@import 'vars';
@import 'node_modules/jeet/jeet';

.servicos-lista {
  $baseInner: 14;
  font-size: #{18/$baseInner}em;
  line-height: 1.6;
  margin: 1em 0;
  .fa {
    margin-right: #{5/$baseInner}em; } }
