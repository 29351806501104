@import 'vars';
@import 'node_modules/jeet/jeet';

$base: 14;

.formulario-interno {
  @include center(#{869/$base}em);
  fieldset {
    border: none;
    margin: #{45/$base}em 0 0;
    padding: 0; }
  legend {
    color: #FEB226;
    font-size: #{17/$base}em;
    font-weight: 700;
    line-height: #{25/$base}em;
    text-transform: uppercase; }
  .inline, label, [type=text], select, textarea {
    @include stack(); }
  .inline {
    label {
      clear: none;
      float: left;
      margin-right: #{20/$base}em;
      width: #{203/$base}em; } }
  label {
    color: #000;
    font-weight: 600;
    margin-top: #{14/$base}em;
    text-indent: #{25/$base}em;
    text-transform: uppercase; }
  [type=text], [type=password], select, textarea {
    border: solid #{1/$base}em #BBB;
    border-radius: #{23.5/$base}em;
    box-sizing: border-box;
    color: #000;
    font-size: 1em;
    margin-top: #{4/$base}em;
    outline: none;
    padding: 0 #{23/$base}em; }
  [type=text], [type=password], select {
    height: #{37/$base}em; }
  textarea {
    height: #{286/$base}em;
    padding: #{11/$base}em #{23/$base}em;
    resize: vertical; }
  [type=submit] {
    $baseInner: 15;
    background: #FEB326;
    border: none;
    border-radius: #{25/$baseInner}em;
    clear: both;
    color: #fff;
    cursor: pointer;
    float: right;
    font-size: #{$baseInner/$base}em;
    height: #{50/$baseInner}em;
    margin-top: #{29/$baseInner}em;
    outline: none;
    text-transform: uppercase;
    transition: box-shadow .4s linear;
    width: #{200/$baseInner}em;
    &:focus,
    &:hover {
      box-shadow: 5px 5px 4px rgba(0,0,0,0.65); } } }
