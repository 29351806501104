@import 'vars';
@import 'node_modules/jeet/jeet';

.rodape-navegacao-container {
  background: url(../images/rodape-navegacao.png);
  padding: #{73/$base}em 0 #{65/$base}em; }

.rodape-navegacao-col-1 {
  @include column(234/$wrapper, $gutter: $gutter, $cycle: 4); }

.rodape-navegacao-col-2 {
  @include column(255/$wrapper, $gutter: $gutter, $cycle: 4); }

.rodape-navegacao-col-3 {
  @include column(260/$wrapper, $gutter: $gutter, $cycle: 4); }

.rodape-navegacao-col-4 {
  @include column(431/$wrapper, $gutter: $gutter, $cycle: 4); }

.rodape-navegacao-titulo {
  $baseInner: 20;
  color: #5f5f5f;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  padding: #{24/$baseInner}em 0 #{18/$baseInner}em #{3/$baseInner}em;
  position: relative;
  text-transform: uppercase;
  &::before {
    background: #666;
    content: '';
    display: inline-block;
    height: #{3/$baseInner}em;
    left: 0;
    position: absolute;
    top: 0;
    width: #{43/$baseInner}em; } }

$baseRodapeLinks: 14;
.rodape-navegacao-lista, .rodape-navegacao-endereco, .rodape-navegacao-telefones {
  $baseInner: $baseRodapeLinks;
  color: #5f5f5f;
  font-size: #{$baseInner/$base}em;
  line-height: 1.5; }

.rodape-navegacao-link, .rodape-navegacao-telefone {
  color: #5f5f5f;
  display: inline-block;
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline; } }

.rodape-navegacao-telefones {
  $baseInner: $baseRodapeLinks;
  display: inline-block;
  margin-top: #{15/$baseInner}em;
  text-align: right; }

.rodape-navegacao-telefone {
  $baseInner: $baseRodapeLinks;
  font-size: #{17/$baseInner}em;
  font-weight: 700; }

.rodape-container {
  background: #4d4d4d;
  color: #fff;
  .wrapper {
    display: flex;
    flex-direction: column;
    font-size: #{12/$base}em;
    height: 40px;
    justify-content: center;
    text-align: center; } }

@media (max-width: #{768/$base}em) {
  $gutter: 2;

  .rodape-navegacao-col-1, .rodape-navegacao-col-2, .rodape-navegacao-col-3,
  .rodape-navegacao-col-4 {
    @include column(1/2, $gutter: $gutter, $cycle: 2); }

  .rodape-navegacao-col-3, .rodape-navegacao-col-4 {
    margin-top: 2em; } }

@media (max-width: #{560/$base}em) {
  .rodape-navegacao-col-1, .rodape-navegacao-col-2, .rodape-navegacao-col-3,
  .rodape-navegacao-col-4 {
    @include stack();
    &:not(.rodape-navegacao-col-1) {
      margin-top: 2em; } } }
