@import 'vars';
@import 'node_modules/jeet/jeet';

.titulo-container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.titulo {
  color: #FEB226;
  font-size: #{36/$base}em;
  line-height: calc(46/36);
  font-weight: 600;
  text-align: center;
  text-transform: uppercase; }

.subtitulo {
  $baseInner: 14;
  color: #777777;
  font-size: #{$baseInner/$base}em;
  font-style: italic;
  line-height: calc(37/14/2);
  margin: #{1/$baseInner}em 0 0 #{66/$baseInner}em;
  position: relative;
  &::before {
    background: #FEB326;
    content: '';
    display: inline-block;
    left: -#{32/$baseInner}em;
    height: #{19/$baseInner}em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: #{1/$baseInner}em; } }

@media (max-width: #{720/$base}em) {
  .titulo-container {
    flex-direction: column;
    align-items: center; }

  .subtitulo {
    margin-left: 0;
    text-align: center;
    &::before {
      content: none; } } }
