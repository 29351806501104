@import 'vars';
@import 'node_modules/jeet/jeet';

.concursos-home-container {
  background-color: #fff;
  .wrapper {
    padding-bottom: #{32/$base}em; } }

.concursos-home-lista {
  display: flex;
  flex-direction: column; }

.concursos-home-item {
  $baseInner: 14;
  align-items: center;
  display: flex;
  float: left;
  margin: #{31/$baseInner}em 0 #{38/$baseInner}em;
  font-size: #{$baseInner/$base}em;
  line-height: #{20/$baseInner}em;
  width: 100%; }

.concursos-internas-lista {
  .concursos-home-item {
    $baseInner: 14;
    border-bottom: solid #{1/$baseInner}em #aaa;
    margin: #{41/$baseInner}em 0 0;
    padding-bottom: #{37/$baseInner}em; }
  ~ .paginacao {
    margin-top: #{29/$base}em; } }

.concursos-home-logo {
  align-items: center;
  background: #fff;
  border: solid #{1/$base}em #ccc;
  display: flex;
  height: #{142/$base}em;
  justify-content: center;
  margin-right: #{32/$base}em;
  width: #{142/$base}em; }

.concursos-home-logo-imagem {
  max-height: 80%;
  max-width: 80%; }

.concursos-home-direita {
  flex: 1; }

.concursos-home-titulo {
  $baseInner: 17;
  color: #777;
  font-size: #{$baseInner/14}em;
  font-weight: 700;
  line-height: #{24/$baseInner}em; }

.concursos-home-datas-item {
  $baseInner: 14;
  margin-top: #{10/$baseInner}em; }

.concursos-home-datas-link {
  color: #777;
  display: flex;
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline; } }

.concursos-home-datas-data {
  $baseInner: 14;
  margin-right: #{12/$baseInner}em;
  padding-right: #{14/$baseInner}em;
  position: relative;
  &::after {
    background: #bbb;
    content: '';
    display: inline-block;
    height: #{14/$baseInner}em;
    position: absolute;
    right: 0;
    top: #{4/$baseInner}em;
    width: #{1/$baseInner}em; } }

.concursos-home-datas-descricao {
  flex: 1; }

.concursos-home-item-rodape {
  $baseInner: 14;
  display: flex;
  margin-top: #{12/$baseInner}em;
  .fa {
    color: #feb226;
    display: inline-block; } }

.concursos-home-botao {
  $baseInner: 14;
  color: #FEB226;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  .fa {
    font-size: #{18/$baseInner}em;
    margin-right: #{7/$baseInner}em;
    vertical-align: middle; }
  &:focus,
  &:hover {
    text-decoration: underline; } }

.concursos-home-status {
  $baseInner: 14;
  color: #feb226;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: #{20/$baseInner}em;
  padding-left: #{20/$baseInner}em;
  position: relative;
  &::after {
    background: #777;
    content: '';
    display: inline-block;
    height: #{14/$baseInner}em;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: #{1/$baseInner}em; } }

.meus-concursos-lista, .meus-concursos-item {
  @include stack(); }

.meus-concursos-item {
  border-bottom: solid #{1/$base}em #aaa;
  padding: #{23/$base}em 0 #{26/$base}em; }

.meus-concursos-link {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:focus,
  &:hover {
    .meus-concursos-titulo, .meus-concursos-acao {
      text-decoration: underline; } } }

.meus-concursos-titulo {
  $baseInner: 17;
  color: #777;
  font-size: #{$baseInner/$base}em;
  font-weight: 700;
  line-height: #{24/$baseInner}em; }

.meus-concursos-acao {
  $baseInner: 14;
  color: #FEB226;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  margin-top: #{12/$baseInner}em;
  text-transform: uppercase;
  .fa {
    $baseInner: 18;
    font-size: #{$baseInner/14}em;
    margin-right: #{7/$baseInner}em;
    vertical-align: middle; } }

@media (max-width: #{560/$base}em) {
  .concursos-home-item {
    flex-direction: column; }

  .concursos-home-logo {
    @include stack();
    display: flex; }

  .concursos-home-titulo {
    margin-top: 1em; } }

@media (max-width: #{380/$base}em) {
  .concursos-home-item-rodape {
    flex-direction: column; }

  .concursos-home-status {
    margin-left: 0;
    margin-top: 1em;
    padding-left: 0;
    &::after {
      content: none; } } }
