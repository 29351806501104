@import 'vars';
@import 'node_modules/jeet/jeet';

.abas-container {
  @include center(#{614/$base}em); }

.abas {
  @include stack();
  @include clearfix();
  padding: #{46/$base}em 0 #{14/$base}em;
  position: relative; }

.aba, .abas-ativa-indicador {
  @include span(1/3); }

.aba {
  $baseInner: 20;
  background: none;
  border: none;
  color: #777;
  cursor: pointer;
  font-size: #{20/$base}em;
  line-height: #{28/$baseInner};
  outline: none;
  text-align: center;
  text-transform: uppercase; }

.aba-status {
  $baseInner: 13;
  @include stack();
  color: #666;
  font-size: #{$baseInner/20}em;
  font-weight: 300;
  line-height: #{20/$baseInner};
  position: relative;
  text-indent: #{150/$baseInner}em; }

.aba-status-quantidade {
  $baseInner: 12;
  color: #00b200;
  font-size: #{$baseInner/13}em;
  line-height: #{19/$baseInner}em;
  margin-top: -#{11/$baseInner}em;
  text-indent: 0;
  position: absolute; }

.abas-ativa {
  @include stack();
  border: solid #{1/$base}em #ccc;
  border-radius: #{15.5/$base}em;
  box-sizing: border-box;
  height: #{31/$base}em;
  padding: #{1/$base}em;
  position: relative; }

.abas-ativa-indicador {
  background-color: #FEB326;
  border-radius: #{13.5/$base}em;
  height: #{27/$base}em;
  right: 0;
  transition: transform .4s linear; }

@media (max-width: #{600/$base}em) {
  .aba {
    font-size: 1em; }

  .aba-status {
    text-indent: 0; } }
