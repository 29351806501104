@import 'vars';
@import 'node_modules/jeet/jeet';

.paginacao {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.paginacao-item {
  position: relative;
  &:not(:last-child) {
    padding-right: #{1/$base}em;
    &::before {
      background-color: #777;
      content: '';
      height: #{20/$base}em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: #{1/$base}em; } } }

.paginacao-link {
  color: #777;
  display: inline-block;
  font-size: #{20/$base}em;
  font-weight: 600;
  line-height: calc(28/20);
  text-align: center;
  text-decoration: none;
  width: #{50/$base}em;
  &:focus,
  &:hover {
    text-decoration: underline; }
  &.paginacao-link-ativo {
    background-color: #FEB326;
    color: #fff; } }
