@import 'vars';
@import 'node_modules/jeet/jeet';
@import 'servicos';

.faq-lista {
  @extend .servicos-lista; }

.faq-termo {
  cursor: pointer;
  &:focus,
  &:hover {
    text-decoration: underline; }
  &.faq-termo-ativo {
    color: #FEB226; } }

.faq-descricao {
  $baseInner: 18;
  display: none;
  font-size: #{14/$baseInner}em; }
