@import 'vars';
@import 'node_modules/jeet/jeet';

$base: 14;

.localizacao-interna {
  @include center(#{867/$base}em);
  color: #777;
  font-weight: 600;
  margin-top: #{6/$base}em;
  .fa {
    color: #FEB226;
    display: inline-block; }
  h4 {
    margin: 0; } }

.localizacao-contatos {
  display: flex;
  flex-direction: row;
  margin-top: #{14/$base}em;
  .fa {
    margin-right: #{15/$base}em;
    vertical-align: middle; } }

.localizacao-telefones {
  .fa {
    font-size: #{18/$base}em; } }

.localizacao-emails {
  margin-left: #{34/$base}em; }

.localizacao-telefone, .localizacao-email {
  color: #777;
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: underline; } }

.localizacao-endereco {
  margin-top: #{12/$base}em;
  .fa {
    margin-right: #{17/$base}em;
    vertical-align: middle; } }

.localizacao-mapa {
  @include stack();
  margin-top: #{17/$base}em;
  position: relative;
  padding-top: calc((324 / 867) * 100%);
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; } }

$base: 16;
@media (max-width: #{720/$base}em) {
  .localizacao-interna {
    margin-top: #{42/$base}em; }
  .localizacao-contatos {
    flex-direction: column; }
  .localizacao-emails {
    margin: #{15/$base}em 0 0; } }

@media (max-width: #{560/$base}em) {
  .localizacao-interna {
    .localizacao-mapa {
      padding-top: 50%; } } }

@media (max-width: #{420/$base}em) {
  .localizacao-interna {
    .localizacao-mapa {
      padding-top: 72%; } } }
