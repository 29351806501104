@import 'vars';
@import 'node_modules/jeet/jeet';

.topo-container {
  .wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    &::after {
      content: none; } } }

.logo-container {
  margin: #{11/$base}em 0 #{20/$base}em;
  width: #{375/$base}em; }

.logo-link,
.logo {
  @include stack();
  height: auto; }

.menu-topo-lista {
  $baseInner: 13;
  color: #777;
  display: flex;
  flex: 0;
  font-size: #{$baseInner/$base}em;
  line-height: #{21/$baseInner}em;
  margin: #{14/$baseInner}em 0 0 #{30/$baseInner}em; }

.menu-topo-item {
  $baseInner: 13;
  padding: 0 #{6/$baseInner}em #{5/$baseInner}em;
  position: relative;
  &:not(:last-child) {
    &::before {
      background-color: #777;
      content: '';
      height: #{13/$baseInner}em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: #{1/$baseInner}em; } }
  &:focus,
  &:hover {
    .menu-topo-link {
      background: #fff;
      color: #FEB226; }
    .menu-topo-sublista {
      opacity: 1;
      transform: none;
      visibility: visible; } } }

.menu-topo-link {
  $baseInner: 13;
  color: #777;
  display: inline-block;
  text-decoration: none;
  padding: #{1/$baseInner}em #{4/$baseInner}em 0;
  white-space: nowrap; }

.menu-topo-sublista {
  $baseInner: 13;
  background-color: #feb326;
  background-clip: padding-box;
  border-top: solid #{4/$baseInner}em transparent;
  line-height: #{(42/2)/$baseInner}em;
  padding: #{11/$baseInner}em #{13/$baseInner}em #{9/$baseInner}em;
  opacity: 0;
  position: absolute;
  transform: translateY(#{50/$baseInner}em);
  transition: opacity .4s linear, transform .4s linear, visibility .4s linear;
  visibility: hidden;
  z-index: 99; }

.menu-topo-sublink {
  $baseInner: 13;
  border-bottom: solid #{1/$baseInner}em transparent;
  color: #555;
  text-decoration: none;
  white-space: nowrap;
  &:focus,
  &:hover {
    border-bottom-color: #666; } }


@media (max-width: #{1060/$base}em) {
  .topo-container {
    .wrapper {
      flex-direction: column; } }

  .menu-topo-lista {
    margin: 0; } }

@media (max-width: #{780/$base}em) {
  .menu-topo-lista {
    flex-wrap: wrap;
    justify-content: center; } }


@media (max-width: #{530/$base}em) {
  .menu-topo-lista {
    $baseInner: 15;
    font-size: #{$baseInner/$base}em; }

  .menu-topo-sublista {
    display: none; } }


@media (max-width: #{480/$base}em) {
  .logo-container {
    width: 100%; } }
