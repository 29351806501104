@import 'vars';
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700&amp;subset=latin-ext';
@import 'reset';
@import 'node_modules/jeet/jeet';
@import 'node_modules/font-awesome/scss/font-awesome';
@import 'libs/formstone/carousel';

@import 'paginacao';

html, body {
  font-family: 'Open Sans', sans-serif;
  font-size: #{$base}px;
  background: #eee; }

.showcss {
  display: block; }

.hidecss {
  display: none; }

button, input, select, textarea {
  font-family: 'Open Sans', sans-serif; }

.container, .topo-container, .banner-container, .concursos-home-container,
.destaques-home-container, .novidades-home-container,
.rodape-navegacao-container, .rodape-container {
  @include stack(); }

.wrapper {
  @include center(#{$wrapper}px);

  @media (max-width: #{1200/$base}em) {
    padding: 0 #{10/$base}em; }

  @media (max-width: #{560/$base}em) {
    padding: 0 #{15/$base}em; } }

@import 'login';
@import 'topo';
@import 'banner';
@import 'abas';
@import 'concursos';
@import 'concurso_detalhes';
@import 'destaques';
@import 'titulo';
@import 'novidades';
@import 'rodape';

// páginas internas
@import 'internas';
@import 'parceiros';
@import 'servicos';
@import 'clientes';
@import 'faq';
@import 'formularios';
@import 'localizacao';
