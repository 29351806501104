@import 'vars';
@import 'node_modules/jeet/jeet';

.concurso-detalhes-info-container {
  align-items: center;
  display: flex;
  float: left;
  width: 100%;
  .concurso-detalhes-logo {
    align-items: center;
    background: #fff;
    border: solid #{1/$base}em #ccc;
    display: flex;
    height: #{142/$base}em;
    justify-content: center;
    margin-right: #{32/$base}em;
    width: #{142/$base}em; }
  .concurso-detalhes-logo-imagem {
    max-height: 80%;
    max-width: 80%; }
  .concurso-detalhes-info {
    flex: 1;
    flex-direction: row;
    .fa {
      color: #feb226;
      display: inline-block; } }
  .concurso-detalhes-titulo {
    $baseInner: 17;
    color: #777;
    font-size: #{$baseInner/14}em;
    font-weight: 700;
    line-height: #{24/$baseInner}em; }
  .concurso-detalhes-itens {
    $baseInner: 14;
    color: #777;
    font-size: #{$baseInner/$base}em;
    line-height: 1.7em; } }

.concurso-detalhes-anexos-container {
  display: flex;
  float: left;
  width: 100%;
  margin-top: 20px;
  table {
    width: 100%;
    tr {
      th {
        text-transform: uppercase;
        font-weight: bold;
        color: #feb226; }
      th, td {
        border: solid #{1/$base}em #ccc;
        padding: 10px; } }
    .fa {
      color: #feb226; } } }
