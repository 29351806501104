@import 'vars';
@import 'node_modules/jeet/jeet';

.destaques-home-container {
  background: url(../images/destaques-fundo.jpg) 50% no-repeat;
  background-size: cover;
  text-align: center; }

.destaques-home-lista {
  display: flex;
  justify-content: space-between; }

.destaques-home-item {
  margin: #{72/$base}em 0 #{69/$base}em;
  max-width: calc((322 / #{$wrapper}) * 100%); }

.destaques-home-link {
  color: #fff;
  text-decoration: none;
  &:focus,
  &:hover {
    .destaques-home-botao {
      background-color: #FEB326;
      border-color: #FEB326; } } }

.destaques-home-icone, .destaques-home-titulo, .destaques-home-descricao {
  @include stack(); }

.destaques-home-icone {
  $baseInner: 80;
  font-size: #{$baseInner/$base}em;
  line-height: #{87/$baseInner}em; }

.destaques-home-titulo {
  $baseInner: 22;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  line-height: #{30/$baseInner}em;
  margin-top: #{33/$baseInner}em;
  text-transform: uppercase; }

.destaques-home-descricao {
  $baseInner: 15;
  font-size: #{$baseInner/$base}em;
  line-height: #{(44/2)/$baseInner}em;
  margin-top: #{14/$baseInner}em; }

.destaques-home-botao {
  $baseInner: 15;
  @include center(#{120/$baseInner}em);
  border: solid #{1/$baseInner}em #fff;
  border-radius: #{20/$baseInner}em;
  font-size: #{$baseInner/$base}em;
  line-height: #{38/$baseInner}em;
  margin-top: #{33/$baseInner}em;
  transition: background-color .4s linear, border-color .4s linear;
  text-transform: uppercase; }

@media (max-width: #{780/$base}em) {
  .destaques-home-item {
    margin: #{72/$base}em 0 #{69/$base}em;
    max-width: 32%; } }

@media (max-width: #{640/$base}em) {
  .destaques-home-lista {
    flex-direction: column; }

  .destaques-home-item {
    max-width: 100%;
    &:not(:first-child):not(:last-child) {
      margin: 2em 0; } } }
