@import 'vars';
@import 'node_modules/jeet/jeet';

.login-container {
  background-color: #feb326; }

.logado-container {
  $baseInner: 13;
  margin-top: #{11/$baseInner}em;
  display: flex;
  flex-direction: column;
  float: right;
  height: #{46/$base}em;
  color: #004DB1;
  font-size: #{$baseInner/$base}em;
  font-weight: 600; }

.logado-acoes {
  $baseInner: 13;
  text-align: right;
  color: #777;
  padding: #{3/$baseInner}em;
  font-size: #{$baseInner/$base}em;
  line-height: #{21/$baseInner}em;
  .logado-area-restrita {
    text-decoration: none;
    color: #004DB1;
    &:focus,
    &:hover {
      text-decoration: none; } }

  .logado-link {
    text-decoration: none;
    color: #004DB1;
    &:not(:first) {
      margin-left: #{3/$baseInner}em; }
    &:focus,
    &:hover {
      text-decoration: underline; } } }


.login-fieldset {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: #{46/$base}em;
  justify-content: center; }

.login-legend {
  $baseInner: 16;
  color: #004CB1;
  font-size: #{$baseInner/$base}em;
  font-weight: 700;
  line-height: #{24/$base}em;
  text-transform: uppercase;
  .fa {
    font-size: #{27/$baseInner}em;
    font-weight: 400;
    margin-left: #{8/$base}em;
    vertical-align: middle; } }

.login-cpf, .login-senha {
  $baseInner: 13;
  @include column(207/1180, $gutter: 5/1180*100);
  border: none;
  box-sizing: border-box;
  color: #000;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  height: #{28/$baseInner}em;
  padding: #{11/$baseInner}em;
  &.login-cpf {
    margin-left: #{10/$baseInner}em; }
  &::placeholder {
    color: #878787;
    font-style: italic; } }

.login-submit {
  $baseInner: 13;
  background: #004DB1;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  height: #{28/$baseInner}em;
  margin-left: #{5/$baseInner}em;
  width: #{79/$baseInner}em;
  text-transform: uppercase; }

.form-acoes {
  $baseInner: 13;
  display: flex;
  flex-direction: column;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  margin-left: #{9/$baseInner}em;
  .form-link {
    $baseInner: 13;
    color: #004DB1;
    text-decoration: none;
    &:not(:first) {
      margin-top: -#{3/$baseInner}em; }
    &:focus,
    &:hover {
      text-decoration: underline; } }
  .fa {
    display: inline-block;
    text-align: center;
    margin-right: #{7/$baseInner}em;
    width: #{12/$baseInner}em; } }

@media (max-width: #{790/$base}em) {
  .login-legend {
    .fa {
      display: none; } } }

@media (max-width: #{760/$base}em) {
  .login-fieldset {
    flex-wrap: wrap;
    justify-content: flex-start;
    height: auto;
    padding: .5em 0; }

  .login-legend {
    flex-basis: 100%;
    margin-bottom: .5em; }

  .login-cpf, .login-senha {
    @include column(1/4, $gutter: 1);
    &.login-cpf {
      margin-left: 0; } } }

@media (max-width: #{570/$base}em) {
  .login-cpf, .login-senha {
    @include column(2/5.5, $gutter: 2); }

  .form-acoes {
    flex-basis: 100%;
    flex-direction: row;
    margin: 1em 0 0; }

  .form-link {
    margin: 0 1em 0 0; } }

@media (max-width: #{400/$base}em) {
  .login-cpf, .login-senha {
    @include column(1/2, $gutter: 2, $cycle: 3); }

  .login-submit {
    $baseInner: 13;
    @include center();
    margin-top: .5em;
    width: #{200/$baseInner}em; } }

