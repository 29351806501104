@import 'vars';
@import 'node_modules/jeet/jeet';

.banner-lista {
  height: 426px;
  .fs-carousel-controls {
    display: flex !important;
    height: 0;
    left: 50%;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: #{$wrapper}px; }
  .fs-carousel-control {
    background: none;
    color: #fff;
    cursor: pointer;
    font-size: #{42/$base}em;
    line-height: #{47/42}em;
    text-shadow: 5px 5px 4px rgba(0,0,0,0.65);
    transition: text-shadow .4s linear;
    &:focus,
    &:hover {
      text-shadow: none; } } }

.banner-item {
  opacity: 0;
  transition: opacity .4s linear, visible .4s linear;
  visibility: hidden;
  &.fs-carousel-visible {
    opacity: 1;
    visibility: visible;
    z-index: 2; } }

.banner-link {
  color: #fff;
  float: left;
  position: relative;
  width: 100%;
  text-decoration: none;
  &:focus,
  &:hover {
    .banner-acao {
      background-color: #FEB326;
      border-color: #FEB326; } } }

.banner-imagem {
  float: left;
  left: 50%;
  position: relative;
  transform: translateX(-50%); }

.banner-texto {
  $baseInner: 17;
  display: inline-block;
  font-size: #{$baseInner/$base}em;
  left: 50%;
  line-height: #{25/$baseInner}em;
  max-width: calc(100vw - #{160/$baseInner}em);
  position: absolute;
  top: calc((80 / 4.26) * 1%);
  transform: translateX(-#{510/$baseInner}em);
  width: #{467/$baseInner}em; }

.banner-acao {
  $baseInner: 15;
  border: solid #{1/$baseInner}em;
  border-radius: #{24/$baseInner}em;
  display: inline-block;
  font-size: #{15/17}em;
  line-height: #{46/$baseInner}em;
  margin: #{34/$baseInner}em 0 0 #{3/$baseInner}em;
  padding: 0 #{32/$baseInner}em;
  transition: background-color .4s linear, border-color .4s linear; }

@media (max-width: #{1200/$base}em) {
  .banner-lista {
    height: auto;
    .fs-carousel-controls {
      width: calc(100vw - #{40/$base}em); } }

  .banner-texto {
    $baseInner: 17;
    left: #{80/$baseInner}em;
    transform: none; } }

@media (max-width: #{1000/$base}em) {
  .banner-imagem {
    height: auto;
    width: 200vw; } }

@media (max-width: #{640/$base}em) {
  .banner-texto {
    $baseInner: 13;
    font-size: #{$baseInner/$base}em;
    top: #{30/$baseInner}em; } }

@media (max-width: #{480/$base}em) {
  .banner-texto {
    top: 1em; }

  .banner-acao {
    display: none; } }

