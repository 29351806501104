@import 'vars';
@import 'node_modules/jeet/jeet';

.novidades-home-container {
  padding: #{63/$base}em 0 #{102/$base}em;
  .paginacao {
    margin-top: #{31/$base}em; } }

.novidades-home-item {
  @include stack();
  display: inline-block;
  margin: #{38/$base}em 0 #{20/$base}em; }

.novidades-home-link {
  color: #999;
  display: flex;
  font-size: #{16/$base}em;
  text-decoration: none;
  &:focus,
  &:hover {
    .novidades-home-data, .novidades-home-descricao {
      text-decoration: underline; } } }

.novidades-detalhes {
  $base: 14;
  @include stack();
  color: #999;
  display: flex;
  font-size: #{16/$base}em;
  margin-top: #{30/$base}em; }

.novidades-home-data {
  $baseInner: 16;
  line-height: #{22/$baseInner}em;
  margin: #{7/$baseInner}em #{18/$baseInner}em 0 0;
  padding-right: #{20/$baseInner}em;
  position: relative;
  white-space: nowrap;
  .fa {
    $baseInner2: 17;
    font-size: #{$baseInner2/$baseInner}em;
    text-align: center;
    margin-right: #{10/$baseInner2}em;
    width: #{20/$baseInner2}em; }
  &::after {
    background: #FEB326;
    content: '';
    display: inline-block;
    height: #{18/$baseInner}em;
    position: absolute;
    right: 0;
    top: #{1/$baseInner}em;
    width: #{1/$baseInner}em; } }

.novidades-data {
  $baseInner: 16;
  @extend .novidades-home-data;
  margin: #{7/$baseInner}em #{18/$baseInner}em 0 0 !important; }

.novidades-home-direita {
  display: flex;
  flex: 1;
  flex-direction: column; }

.novidades-direita {
  flex: 1;
  first-child: {
    margin-top: 0; }
  last-child: {
    margin-bottom: 0; } }

.novidades-home-descricao {
  $baseInner: 14;
  font-size: #{$baseInner/$base}em;
  line-height: #{(39/$baseInner)/2}em; }

.novidades-home-chamada {
  $baseInner: 14;
  color: #FEB226;
  font-size: #{$baseInner/$base}em;
  font-weight: 600;
  line-height: #{20/$baseInner}em;
  margin-top: #{12/$baseInner}em;
  text-decoration: none;
  .fa {
    font-size: #{18/$baseInner}em;
    margin-right: #{7/$baseInner}em;
    vertical-align: middle; } }

@media (max-width: #{560/$base}em) {
  .novidades-home-link,
  .novidades-detalhes {
    flex-direction: column; }
  .novidades-home-data {
    $baseInner: 16;
    margin: 0 0 1em !important;
    padding: 0 0 1em;
    &::after {
      bottom: 0;
      height: #{1/$baseInner}em;
      left: 0;
      right: auto;
      top: auto;
      width: #{18/$baseInner}em; } } }
