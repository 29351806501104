@import 'vars';
@import 'node_modules/jeet/jeet';

.parceiros-logo {
  $baseInner: 14;
  align-items: center;
  background: #fff;
  border: solid #{1/$baseInner}em #ccc;
  display: flex;
  float: left;
  height: #{142/$baseInner}em;
  justify-content: center;
  margin: 0 #{40/$baseInner}em #{30/$baseInner}em 0;
  width: #{142/$baseInner}em;
  ~ & {
    margin-top: #{30/$baseInner}em; } }

.parceiros-logo-imagem {
  max-height: 80%;
  max-width: 80%; }

@media (max-width: #{560/$base}em) {
  .parceiros-logo {
    @include stack();
    display: flex; } }
