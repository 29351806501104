@import 'vars';
@import 'node_modules/jeet/jeet';

.internas-container {
  background-color: #fff;
  .wrapper {
    padding: #{68/$base}em 0 #{61/$base}em; } }

.editor {
  $baseInner: 14;
  color: #777;
  font-size: #{$baseInner/$base}em;
  line-height: 1.6em;
  @import 'normalize';
  h1, h2, h3, h4, h5, h6 {
    display: block;
    font-weight: 600;
    margin: 1.6em 0;
    text-transform: uppercase;
    + p {
      margin-top: -1.6em; } }
  h1 {
    font-size: 1.4em; }
  h2 {
    font-size: 1.25em; }
  h3 {
    font-size: 1.1em; }
  h4 {
    font-size: 1em; }
  h5 {
    font-size: .9em; }
  h6 {
    font-size: .8em; }
  p {
    margin: 1.6em 0; }
  em, i {
    font-style: italic; }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    th {
      height: 50%;
      border: 1px solid black;
      padding: 15px;
      font-weight: bold;
      border-color: #777;
      color: #FEB226; }
    td {
      border: 1px solid black;
      padding: 15px; } }
  .dados-cargo {
    @include center(#{869/$baseInner}em);
    text-align: center;
    border: 1px solid #777; } }

.botao {
  $baseInner: 15;
  background: #FEB326 !important;
  border: none;
  border-radius: #{25/$baseInner}em;
  clear: both;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: #{$baseInner/14}em;
  line-height: #{50/$baseInner}em;
  margin: 0 #{5/$baseInner}em;
  outline: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow .4s linear;
  width: #{222/$baseInner}em;
  &:focus,
  &:hover {
    box-shadow: 5px 5px 4px rgba(0,0,0,0.65); }
  &.botao-centralizado {
    @include center(#{222/$baseInner}em);
    margin-top: #{24/$baseInner}em; } }

.botoes-centralizados {
  $baseInner: 14;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: #{40/$baseInner}em; }

.capitalize {
  text-transform: capitalize; }

.link {
  $baseInner: 15;
  color: #FEB226;
  display: inline-block;
  font-size: #{$baseInner/14}em;
  font-weight: 700;
  line-height: #{22/$baseInner}em;
  text-decoration: none;
  text-transform: uppercase;
  .fa {
    font-size: #{17/$baseInner}em;
    margin-right: #{calc(10/17)}em;
    vertical-align: top; }
  &:focus,
  &:hover {
    text-decoration: underline; }
  & ~ & {
    margin-left: #{25/$baseInner}em; } }

.links-direita {
  $baseInner: 14;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-top: #{28/$baseInner}em; }

.azul {
  color: #004CB1; }

@media (max-width: #{1200/$base}em) {
  .internas-container {
    .wrapper {
      padding: #{50/$base}em #{15/$base}em; } }
  .editor {
    margin-top: 1.6em; } }

@media (max-width: #{768/$base}em) {
  .botoes-centralizados {
    align-items: center;
    flex-direction: column; }
  .botao {
    $baseInner: 15;
    margin: #{5/$baseInner}em; }
  .links-direita {
    justify-content: center; } }
